<template>
  <div class="status-reason-bg" v-if="visible"></div>
  <div class="status-reason-inner" v-if="visible">
    <div class="card">
      <div class="card-body">
        <p><strong>Reason&nbsp;for&nbsp;closure:</strong></p>
        <SelectInput
          name="application_status"
          label=""
          :with-blank-option="false"
          :options="options"
          @selected="updateSelect"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StatusReasonModal",
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  emits: ["updated"],
  data: () => ({
    visible: false
  }),
  methods: {
    updateSelect(param) {
      this.$emit("updated", param);
    }
  }
};
</script>
<style lang="scss" scoped>
.status-reason {
  &-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: 0.5;
  }

  &-inner {
    position: fixed;
    min-width: 300px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
}
</style>
