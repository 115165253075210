<template>
  <div class="status-update">
    <strong v-if="withTitle">Status:</strong>
    <div v-if="!edit" >
      <p class="p-0 m-0" @click="handleEdit">
      <span :class="['badge badge-pill pointer', badgeStyle]">{{
          getStatus
        }}</span>
      </p>
      <div class="mt-2" v-if="getStatusChangeDate !== null">
        <strong>Date changed:</strong> {{getStatusChangeDate}} <br>
        <strong>Updated by:</strong> {{getCausedBy}}
      </div>
    </div>
    <div v-else class="position-relative">
      <div
          v-if="updating"
          class="overlay-div d-flex align-items-center justify-content-center"
      >
        <p class="blink p-0 m-0">updating...</p>
      </div>
      <SelectInput
          :options="optionsComputed"
          :value="getStatusRaw"
          :with-blank-option="false"
          label=""
          name="application_status"
          @selected="updateSelect"
          @keyup.esc="handleEscape"
      />
    </div>
    <StatusReasonModal
        ref="statusReasonModal"
        :options="getStatusClosedReason"
        @updated="handleReason"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import StatusReasonModal from "@/components/modal/StatusReasonModal";

export default {
  name: "StatusUpdate",
  props: {
    type: {
      type: String,
      default: "trace"
    },
    inactive: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: null
    },
    withTitle: {
      type: Boolean,
      default: true
    },
    reasonRequired: {
      type: Boolean,
      default: true
    }
  },
  emits: ["status-changed"],
  data: () => ({
    status: null,
    edit: false,
    updating: false
  }),
  components: {
    StatusReasonModal
  },
  computed: {
    ...mapGetters([
      "getApplication",
      "getTracedPension",
      "getStatusClosedReason",
      "getStatusAppValues",
      "getStatusTPValues"
    ]),
    optionsComputed() {
      let optionsTemp = this.type === 'app' ? this.getStatusAppValues : this.getTPStatusFilter;
      if (optionsTemp.length === 0) {
        this.loadStatusLists();
        optionsTemp = this.type === 'app' ? this.getStatusAppValues : this.getTPStatusFilter;
      }
      return optionsTemp;
    },
    getTPStatusFilter() {
      if (this.getTracedPension?.current_provider === null || this.getTracedPension?.current_provider?.name === null) {
        return this.getStatusTPValues.filter(i => i.text !== 'Generate & Send LOA');
      }

      return this.getStatusTPValues;
    },
    getStatusRaw() {
      return this.entity?.status || this.getApplication?.status;
    },
    getStatus() {
      return this.deCamel(this.entity?.status || this.getApplication?.status);
    },
    getStatusChangeDate() {
      return this.formatReadDateTime(this.entity?.current_traced_pension_state?.entered_at || this.getApplication?.current_application_state?.entered_at) || null;
    },
    getCausedBy() {
      if(this.type === 'trace') {
        if(this.entity?.current_traced_pension_state?.caused_by === null) {
          return 'Automation';
        }
        return this.entity?.current_traced_pension_state?.caused_by?.first_name + ' ' + this.entity?.current_traced_pension_state?.caused_by?.last_name;
      }

      if(this.type === 'app') {
        if(this.getApplication?.current_application_state?.caused_by === null) {
          return 'Automation';
        }
        return this.getApplication?.current_application_state?.caused_by?.first_name + ' ' + this.getApplication?.current_application_state?.caused_by?.last_name;
      }

      return 'Automation';
    },
    badgeStyle() {
      return {
        "badge-primary":
            this.getStatusRaw === "in-progress" ||
            this.getStatusRaw === "Pension Search" ||
            this.getStatusRaw === "Generate & Send LOA" ||
            this.getStatusRaw === "OP Chaser" ||
            this.getStatusRaw === "LOR Review" ||
            this.getStatusRaw === "LOR Translate In Progress",

        "badge-warning":
            this.getStatusRaw === "Request Wet Signature (Required by OP)" ||
            this.getStatusRaw === "Request Wet Signature (Missing from app)" ||
            this.getStatusRaw === "Customer - Missing Info" ||
            this.getStatusRaw === "Employer - More Info Needed" ||
            this.getStatusRaw === "LOR - Missing Info" ||
            this.getStatusRaw === "Multiple LOA",

        "badge-danger":
            this.getStatusRaw === "Pension Not Found" ||
            this.getStatusRaw === "Provider Not Matched" ||
            this.getStatusRaw === "signature-needed" ||
            this.getStatusRaw === "LOR Translate Failed" ||
            this.getStatusRaw === "LOR Validation Fail" ||
            this.getStatusRaw === "Too Long Scheme Name",


        "badge-info":
            this.getStatusRaw === "Generate Pension Report" ||
            this.getStatusRaw === "processing-lor" ||
            this.getStatusRaw === "Pension Report QA",

        "badge-success":
            this.getStatusRaw === "complete" ||
            this.getStatusRaw === "LOR - Missing Info Received" ||
            this.getStatusRaw === "Send Pension Report" ||
            this.getStatusRaw === "LOR Translate Complete" ||
            this.getStatusRaw === "LOR Translate Failed",

        "badge-secondary":
            this.getStatusRaw === "declined" ||
            this.getStatusRaw === "duplicate" ||
            this.getStatusRaw === "withdrew-from-the-service" ||
            this.getStatusRaw === "closed" ||
            this.getStatusRaw === "PCS Closed"
      };
    }
  },
  methods: {
    ...mapMutations([
      "setApplicationStatus",
      "setTraceStatus",
      "setClosedConditions"
    ]),
    ...mapActions([
      "fetchStatusList",
    ]),
    handleEscape() {
      this.edit = false;
    },
    handleReason(reason) {
      let params = {
        uuid: this.entity?.uuid || null,
        status: this.status,
        closed_reason: reason
      };
      this.$refs.statusReasonModal.visible = false;
      this.$emit("status-changed", params);
    },
    handleEdit() {
      if (this.inactive) return;
      this.edit = true;
    },
    updateSelect(param) {
      if (this.inactive) return;
      this.updating = true;
      let params = {
        uuid: this.entity?.uuid || null,
        status: param,
        pushFinderMatch: false
      };
      if (this.reasonRequired && param === "complete") {
        this.status = param;
        this.$refs.statusReasonModal.visible = true;
      } else {
        this.$emit("status-changed", params);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.badge {
  padding: 10px;
  border-radius: 3px;
}
</style>
